import React from "react";
import { graphql } from "gatsby";
import FeedCard from "../components/feed-card";
import Layout from "../components/layout";
import SEO from "../components/seo";
import LoadMore from "../components/load-more";
import { colors, H5 } from "../style/theme";

function Index({ data }) {
  const onHome = data.postgres.allFeeds.nodes.filter(
    (node) => node.blogByBlogId.onHome
  ).slice(0, 10);;


  return (
    <Layout>
      <SEO title="الرئيسية" />
      <H5
        css={{
          color: colors.dark,
          padding: "1rem 1rem 0.7rem 1rem",
          margin: "1.5rem auto",
          boxShadow: "1px 2px 15px #e5e5e5",
          backgroundColor: colors.light,
        }}
      >
        أحدث التدوينات المنشورة في الفضاء العربي
      </H5>
      {onHome.map((feed) => (
        <FeedCard
          node={{
            url: feed.url,
            title: feed.title,
            image: feed.offlineImage,
            isoDate: feed.isoDate,
            contentSnippet: feed.contentSnippet,
            blogTitle: feed.blogByBlogId.title,
            blogId: feed.blogId,
            onHome: feed.blogByBlogId.onHome,
          }}
          key={feed.nodeId}
        />
      ))}
      <LoadMore />
    </Layout>
  );
}

export default Index;

export const pageQuery = graphql`
  query Index {
    postgres {
      allFeeds(
        orderBy: ISO_DATE_DESC,
        first: 20
        ) {
        nodes {
          url
          title
          offlineImage
          isoDate
          contentSnippet
          publishedAt
          nodeId
          blogId
          blogByBlogId {
            onHome
            title
            url
          }
          fetchedAt
        }
      }
    }
  }
`;
