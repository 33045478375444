import React, { useEffect, useState } from "react";
import FeedCard from "../components/feed-card";
import { Button } from "../style/theme";
import axios from "axios";
import { API_URL } from "../../env";

const inWindow = typeof window !== "undefined";

function useScroll() {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [bodyOffset, setBodyOffset] = useState(
    inWindow && document.body.getBoundingClientRect()
  );
  const [scrollY, setScrollY] = useState(bodyOffset.top);
  const [scrollX, setScrollX] = useState(bodyOffset.left);
  const [scrollDirection, setScrollDirection] = useState();

  const listener = (e) => {
    setBodyOffset(inWindow && document.body.getBoundingClientRect());
    setScrollY(-bodyOffset.top);
    setScrollX(bodyOffset.left);
    setScrollDirection(lastScrollTop > -bodyOffset.top ? "down" : "up");
    setLastScrollTop(-bodyOffset.top);
  };

  useEffect(() => {
    if (inWindow) {
      window.addEventListener("scroll", listener);
      return () => {
        window.removeEventListener("scroll", listener);
      };
    }
  });

  return [scrollY, scrollX, scrollDirection];
}

const useFetchmore = () => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isNoMore, setIsNoMore] = useState(false);

  const url = `${API_URL}/feeds/?page=${page}`;

  const fetchData = async () => {
    if (page > 15) {
      setIsNoMore(true);
    } else {
      setIsLoading(true);
      setIsError(false);
      try {
        const result = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: "Basic ZWxmZWhyZXN0Ok5qNG1NSlEwd0Z6MUJTakR6THQz",
          },
        });
        console.log(result);
        if (data.length === 0) {
          setData(result.data.data);
        } else {
          setData([...data, ...result.data.data]);
        }
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
      }
      setPage(page + 1);
      setIsLoading(false);
    }
  };
  return [fetchData, isError, isLoading, isNoMore, data];
};

function LoadMore() {
  const [fetchData, isError, isLoading, isNoMore, data] = useFetchmore();
  const [scrollY, scrollX] = useScroll();
  const [buttonMes, setButtonMes] = useState("اعرض المزيد");

  useEffect(() => {
    const [x, y] = [scrollX, scrollY];
    window.scrollTo(x, y);
  }, [data]);

  useEffect(() => {
    setButtonMes(isLoading ? "نجلب المزيد..." : "اعرض المزيد");
  }, [isLoading]);

  return (
    <div>
      {isError && <div>خطأٌ ما حصل من طرفنا!</div>}

      <div>
          {data.map((feed) => {
          return (
            <div key={feed.id}>
              <FeedCard
                node={{
                  ...feed,
                  isoDate: feed.iso_date,
                  contentSnippet: feed.content_snippet,
                  blogTitle: feed.blog.title,
                  onHome: feed.blog.on_home,
                  blogId: feed.blog_id,
                  image: feed.offline_image,
                }}
              />
            </div>
          );
        })}
        <div
          css={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          {isNoMore || (
            <Button onClick={isLoading || fetchData}>{buttonMes}</Button>
          )}
        </div>
      </div>
      {isNoMore && (
        <div
          css={{
            margin: "1rem auto",
            width: "100%",
            textAlign: "center",
          }}
        >
          هذا مُذهل! لقد قرأت الكثير! لا مزيد لعرضه حاليًا
        </div>
      )}
    </div>
  );
}

export default LoadMore;
